<template>
  <div class="add">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{
        Number(id) === 0 ? "添加活动" : "编辑活动"
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="活动标题">
        <el-input v-model="formData.title" placeholder="请输入活动标题"></el-input>
      </el-form-item>
      <el-form-item label="活动内容" class="col">
        <!-- <quill-editor
          class="richEdit"
          v-model="formData.details"
          :options="quillOption"
        >
        </quill-editor> -->
        <VueUeditorWrap v-model="formData.details" :config="myConfig" ref="myTextEditor"></VueUeditorWrap>

        <pre>
            活动页面跳转说明：
              如果活动页面需要点击事件和页面跳转 请点击源码编辑 编辑源码
              1、如果是文字事件 请在要跳转的文字 增加a标签
                 比如：万旅网  在源码找到万旅网这三个字 修改成 < a href=" ">万旅网</ a>
              2、如果是图片事件 在源码找到该图片的标签 增加 onclick方法
                 比如：图片1.jpg 需要有事件 找到 < img src="1.jpg"..> 修改为
                 < img onclick="方法名();" src="1.jpg"..>
              3、方法名：
               获取优惠券方法：getCoupon(优惠券Id);
               页面跳转方法：navPage(跳转类型，页面Id);
               登录注册方法：isLogin();
              4、优惠券Id说明 ：请去优惠券列表查询d对应的Id
                 跳转类型： 1 酒店列表页 2 酒店详细页面 3 门票列表页 4 门票详细页面 5 线路列表页 6 线路详细页面 7 拼团首页 8 秒杀首页
                   9 精选商城-商品详情 10 拼团详情 11 秒杀详情  12 活动页
                 页面Id 对应的类型ID 其中：1 3 7 8 页面Id输入0 即可 
           </pre>
      </el-form-item>
      <el-form-item label="活动状态">
        <el-radio v-model="formData.status" :label="1">显示</el-radio>
        <el-radio v-model="formData.status" :label="2">隐藏</el-radio>
      </el-form-item>
      <el-form-item label="分享标题">
        <el-input v-model="formData.shareTitle" placeholder="请输入分享标题"></el-input>
      </el-form-item>
      <el-form-item label="分享图片">
        <send-image type="one" :addOrEdit="formData.shareImg ? 1 : 0" :images="formData.shareImg"
          @addImageStr="addImageStr"></send-image>
      </el-form-item>
      <el-form-item label="活动时间">
        <el-date-picker v-model="activeTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import VueUeditorWrap from 'vue-ueditor-wrap'
import { Quill } from "vue-quill-editor";
import quillConfig from "../../../../quill-config";
import { addOrEditActive, activeDetail } from "../../../api/activePage";
import sendImage from "../../../components/sendImage.vue";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: { sendImage, VueUeditorWrap },
  name: "addActive",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "insertvideo",
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        serverUrl: "/config",
        // serverUrl: "/api/config",
        //  UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      activeTime: [],
      formData: {
        activityId: null,
        title: "",
        details: "",
        status: 1,
        shareTitle: '',
        shareImg: '',
        beginTime: '',
        endTime: '',
      },
    };
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  created() {
    this.formData.activityId = Number(this.id);
    if (Number(this.id) > 0) {
      this.getActiveDetail();
    }
  },
  methods: {
    addImageStr(image) {
      console.log('回调图片', image)
      this.formData.shareImg = image;
      console.log('回调图片2', this.formData.shareImg)
    },
    async getActiveDetail() {
      const { data } = await activeDetail({
        activityId: Number(this.id),
      });
      this.formData.activityId = data.data[0].activity_id;
      this.formData.title = data.data[0].activity_title;
      this.formData.details = data.data[0].activity_details;
      this.formData.status = data.data[0].activity_status;
      this.formData.shareImg = data.data[0].activity_share_img;
      this.formData.shareTitle = data.data[0].activity_share_title;
      this.activeTime.push(new Date(data.data[0].activity_beginTime))
      this.activeTime.push(new Date(data.data[0].activity_endTime))

    },
    async onSave() {
      // console.log(this.formatDate(this.activeTime[0]), '==')
      this.formData.beginTime = this.formatDate(this.activeTime[0]) || '';
      this.formData.endTime = this.formatDate(this.activeTime[1]) || '';
      for (const key in this.formData) {
        if (this.formData[key] === "" || this.formData[key] === null) {
          console.log(key, this.formData[key])
          return this.$message.warning("请把数据填写完整!");
        }
      }
      console.log(this.formData)
      const { data } = await addOrEditActive(this.formData);
      if (data.code === 0) {
        this.$message.success(data.msg);
        this.$router.go(-1);
      } else {
        this.$message.error(data.msg);
      }
    },
    formatDate (d) {
   var date = new Date(d);
   var YY = date.getFullYear() + '-';
   var MM =
     (date.getMonth() + 1 < 10
       ? '0' + (date.getMonth() + 1)
       : date.getMonth() + 1) + '-';
   var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
   var hh =
     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
   var mm =
     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
     ':';
   var ss =
     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
   return YY + MM + DD + ' ' + hh + mm + ss;
 },
  },
};
</script>
<style lang="less" scoped>
.add {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  /deep/.el-form {
    margin-top: 50px;

    .col {
      .el-form-item__content {
        display: flex;

        pre {
          color: red;
        }
      }
    }

    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 600px;
        }

        .richEdit {
          width: 600px;
          height: 600px;

          .ql-container {
            height: 510px;
          }
        }
      }
    }
  }
}
</style>